tui-dialog[data-appearance='s-long'] {
  &[data-size='s'] {
    width: 40rem;
  }
}

.tui-dialog-content {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tui-scrollbar {
  &_with-header {
    height: calc(80vh - 92px);
  }

  &_none-header {
    height: calc(80vh - 42px);
  }

  &_table {
    height: calc(80vh - 259px);
    margin-top: 20px;
  }
}
